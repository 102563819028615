'use client'

import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import MerchandiseCard from 'src/components/merchandise/MerchandiseCard'
import { useMerchandises } from 'src/hooks/merchandise.client'
import HomePageSection from '../default/HomePageSection'
import HomeCardListBlock, { HomeCardListResponsiveOptions } from './HomeCardListBlock'

const HomeMerchandiseSection: React.FC<{ responsiveOptions?: HomeCardListResponsiveOptions }> = ({
  responsiveOptions,
}) => {
  const { loadingMerchandises, merchandises } = useMerchandises({
    limit: 4,
  })
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView && merchandises.length > 0) {
      window.gtag?.('event', 'view_item_list', {
        items: merchandises.map(merchandise => {
          const categoryName = merchandise.categories[0]?.name || ''
          const memberName = merchandise.seller?.displayName || ''
          const listPrice = merchandise.listPrice
          const price = merchandise.listPrice
          const discount = listPrice - price

          return {
            item_id: `Activity_${merchandise.id}`,
            item_name: merchandise.title,
            item_category: 'Merchandise',
            item_category2: categoryName,
            item_category3: memberName,
            quantity: '1',
            discount,
            price,
          }
        }),
      })
    }
  }, [inView, merchandises])

  if (!loadingMerchandises && merchandises.length === 0) {
    return null
  }

  return (
    <div ref={ref}>
      <HomePageSection
        loading={loadingMerchandises}
        center
        title="所有商品"
        extra={
          <Button href="/merchandises" shape="round">
            查看更多商品 »
          </Button>
        }
      >
        <HomeCardListBlock
          className="mt-3 mt-sm-4"
          responsiveOptions={responsiveOptions}
          loading={loadingMerchandises}
          dataSource={merchandises}
          render={(merchandise, index) => <MerchandiseCard merchandise={merchandise} priority={index === 0} />}
        />
      </HomePageSection>
    </div>
  )
}

export default HomeMerchandiseSection
