'use client'

import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import CourseCard from 'src/components/course/CourseCard'
import { useCourses } from 'src/hooks/course.client'
import HomePageSection from '../default/HomePageSection'
import HomeCardListBlock, { HomeCardListResponsiveOptions } from './HomeCardListBlock'

const HomeCourseSection: React.FC<{ responsiveOptions?: HomeCardListResponsiveOptions }> = ({ responsiveOptions }) => {
  const { courses, loadingCourses } = useCourses({ limit: 4, status: 'published' })
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView && courses.length > 0) {
      window.gtag?.('event', 'view_item_list', {
        items: courses.map(course => {
          const categoryName = course.categories[0]?.name || ''
          const memberName = course.instructors[0]?.displayName || ''
          const listPrice = course.listPrice
          const price = course.salePrice ?? listPrice
          const discount = listPrice - price

          return {
            item_id: `Course_${course.id}`,
            item_name: course.title,
            item_category: 'Course',
            item_category2: categoryName,
            item_category3: memberName,
            quantity: '1',
            discount,
            price,
          }
        }),
      })
    }
  }, [inView, courses])

  if (!loadingCourses && courses.length === 0) {
    return null
  }

  return (
    <div ref={ref}>
      <HomePageSection
        loading={loadingCourses}
        center
        title={<FormattedMessage id="page.home.allCourses" />}
        extra={
          <Button href="/courses" shape="round">
            <FormattedMessage id="page.home.seeMoreCourses" />
            <span className="ml-1">»</span>
          </Button>
        }
      >
        <HomeCardListBlock
          className="mt-3 mt-sm-4"
          responsiveOptions={responsiveOptions}
          loading={loadingCourses}
          dataSource={courses}
          render={(course, index) => <CourseCard course={course} priority={index === 0} />}
        />
      </HomePageSection>
    </div>
  )
}

export default HomeCourseSection
