'use client'

import { Skeleton, Space } from 'antd'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import { styled } from 'styled-components'
import { StyledTitleBlockDiv } from './index.styled'

type HomePageSectionProps = {
  loading?: boolean
  className?: string
  title?: React.ReactNode
  subtitle?: React.ReactNode
  center?: boolean
  extra?: React.ReactNode
}

const StyledPageSectionDiv = styled.div`
  padding: 20px 0;
  @media screen and (min-width: ${BREAK_POINT}px) {
    padding: 60px 0;
  }

  .ant-skeleton-title {
    margin-bottom: 0px;
  }
  ul.ant-skeleton-paragraph {
    margin-top: 16px !important;
    margin-bottom: 0px;
  }
`

const HomePageSection: React.FC<React.PropsWithChildren<HomePageSectionProps>> = ({
  loading,
  children,
  title = null,
  subtitle = null,
  extra = null,
  center = false,
  className,
  ...rest
}) => {
  const justifyClassName = useMemo(
    () => (center ? 'justify-content-around' : extra ? 'justify-content-between' : ''),
    [center, extra],
  )

  return (
    <StyledPageSectionDiv className={className} {...rest}>
      <div className="container">
        <Skeleton loading={loading} paragraph={{ rows: 1, width: '100%' }}>
          <div className={clsx('d-flex top align-items-center mb-6', justifyClassName)}>
            <StyledTitleBlockDiv className={center ? 'text-center' : 'px-4'}>
              <h2 className="title mb-1">{title}</h2>
              <div className="description">{subtitle}</div>
            </StyledTitleBlockDiv>

            {extra && (
              <div>
                <Space>{extra}</Space>
              </div>
            )}
          </div>
        </Skeleton>
      </div>

      {children}
    </StyledPageSectionDiv>
  )
}

export default HomePageSection
