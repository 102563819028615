'use client'

import { Button } from 'antd'
import Link from 'next/link'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import AppointmentCard from 'src/components/appointment/AppointmentCard'
import { useAppointments } from 'src/hooks/appointment.client'
import HomePageSection from '../default/HomePageSection'
import HomeCardListBlock, { HomeCardListResponsiveOptions } from './HomeCardListBlock'

const HomeAppointmentSection: React.FC<{ responsiveOptions?: HomeCardListResponsiveOptions }> = ({
  responsiveOptions,
}) => {
  const { loadingAppointments, appointments } = useAppointments({
    limit: 4,
  })
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView && appointments.length > 0) {
      window.gtag?.('event', 'view_item_list', {
        items: appointments.map(appointment => {
          const categoryName = appointment.categories[0]?.name || ''
          const memberName = appointment.author?.displayName || ''
          const listPrice = appointment.listPrice
          const price = appointment.listPrice
          const discount = listPrice - price

          return {
            item_id: `Appointment_${appointment.id}`,
            item_name: appointment.title,
            item_category: 'Appointment',
            item_category2: categoryName,
            item_category3: memberName,
            quantity: '1',
            discount,
            price,
          }
        }),
      })
    }
  }, [inView, appointments])

  if (!loadingAppointments && appointments.length === 0) {
    return null
  }

  return (
    <div ref={ref}>
      <HomePageSection loading={loadingAppointments} center title="所有預約">
        <HomeCardListBlock
          className="mt-3 mt-sm-4"
          responsiveOptions={responsiveOptions}
          loading={loadingAppointments}
          dataSource={appointments}
          render={(appointment, index) => <AppointmentCard appointment={appointment} priority={index === 0} />}
        />

        <div className="d-flex justify-content-center">
          <Link href="/appointments">
            <Button shape="round" className="mt-2 px-5">
              <FormattedMessage id="page.button.showMore" />
            </Button>
          </Link>
        </div>
      </HomePageSection>
    </div>
  )
}

export default HomeAppointmentSection
