'use client'

import React, { useCallback, useState } from 'react'
import CarouselSection from 'src/components/carousel'
import { CarouselProps } from 'src/types/carousel'
import Section from './HomePageSection'

const HomePromotionSection: React.FC = () => {
  const [sectionTitle, setSectionTitle] = useState('課程優惠')
  const [sectionSubtitle, setSectionSubtitle] = useState<string | null>('解鎖新課程')

  const [loadingCarousel, setLoadingCarousel] = useState(true)
  const [carousel, setCarousel] = useState<CarouselProps | null>(null)

  const onCarouselFetch = useCallback((carousel: CarouselProps) => {
    setSectionTitle(carousel.name)
    setSectionSubtitle(carousel.description)

    setCarousel(carousel)
    setLoadingCarousel(false)
  }, [])

  const onCarouselLoaded = useCallback(() => {
    setLoadingCarousel(false)
  }, [])

  if (!loadingCarousel || !carousel || carousel.slides.length === 0) {
    return null
  }

  return (
    <Section className="py-4 py-sm-5" title={sectionTitle} subtitle={sectionSubtitle}>
      <div className="mt-4">
        <CarouselSection block="home_discount" onCarouselFetch={onCarouselFetch} onCarouselLoaded={onCarouselLoaded} />
      </div>
    </Section>
  )
}

export default HomePromotionSection
