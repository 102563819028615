'use client'

import { Button, Skeleton } from 'antd'
import Link from 'next/link'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ArticleRow from 'src/components/article/ArticleRow'
import SpacedCarousel from 'src/components/common/carousel/SpacedCarousel'
import StructuredData from 'src/components/common/StructuredData'
import { useApp } from 'src/contexts/AppContext'
import { BREAK_POINT_SM, Responsive } from 'src/contexts/ResponsiveContext'
import { useArticles } from 'src/hooks/article.client'
import { StyledArticleRowWrapper } from 'src/views/ArticleListPage/default'
import styled from 'styled-components'
import HomePageSection from '../default/HomePageSection'

const StyledArticleCard = styled.div`
  position: relative;
  border-radius: var(--default-border-radius, 16px);
  box-shadow: var(--theme-box-shadow);
  background-color: var(--theme-component-background-color);
  width: 100%;
  height: 100%;
  min-height: 380px;
  max-width: var(--card-max-width);
  padding: 15px 20px;
  margin: 0 auto;

  @media screen and (min-width: ${BREAK_POINT_SM}px) {
    height: auto;
    max-width: unset;
    min-height: unset;
  }
`
const StyledMobileBlock = styled.div`
  width: 100%;
  height: 100%;
`

const HomeArticleSection: React.FC<{ isFullWidth?: boolean }> = ({ isFullWidth }) => {
  const { formatMessage } = useIntl()
  const { frontDomain } = useApp()
  const { articles, loadingArticles } = useArticles({
    limit: 4,
    orderBy: 'latest',
  })
  if (!loadingArticles && articles.length === 0) {
    return null
  }

  return (
    <HomePageSection
      loading={loadingArticles}
      title={<FormattedMessage id="page.home.featuredArticles" />}
      subtitle={<FormattedMessage id="page.home.featuredArticlesSubtitle" />}
      center
    >
      <StructuredData
        data={{
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          name: formatMessage({ id: 'page.home.featuredArticles' }),
          numberOfItems: articles.length,
          itemListOrder: 'Descending',
          itemListElement: articles.map(article => ({
            '@type': 'Article',
            headline: article.title,
            image: article.featuredImageUrl ? [article.featuredImageUrl] : [],
            url: article.path,
            datePublished: article.onlineAt?.toISOString(),
            dateModified: article.updatedAt?.toISOString(),
            author: article.author
              ? [
                  {
                    '@type': 'Person',
                    name: article.author.displayName || '',
                    url: `https://${frontDomain}/author/${article.author.displayName || ''}`,
                  },
                ]
              : undefined,
          })),
        }}
      />

      <Responsive.Media greaterThanOrEqual="md">
        <div className="container mt-4">
          <Skeleton loading={loadingArticles}>
            <div className="row justify-content-center">
              {articles.map(article => (
                <div className={isFullWidth ? 'col-12' : 'col-12 col-lg-9'} key={article.id}>
                  <StyledArticleCard className="px-3 py-3 mb-4">
                    <StyledArticleRowWrapper>
                      <ArticleRow
                        article={article}
                        noCategory
                        showContinueReadingButton
                        showAbstract
                        datetimeFormat="YYYY-MM-DD"
                      />
                    </StyledArticleRowWrapper>
                  </StyledArticleCard>
                </div>
              ))}
            </div>
          </Skeleton>
        </div>
      </Responsive.Media>

      <Responsive.Media lessThan="md">
        <div className="mt-3">
          <Skeleton loading={loadingArticles}>
            {!loadingArticles && articles.length > 0 && (
              <>
                {articles.length > 1 ? (
                  <SpacedCarousel
                    dataSource={articles}
                    showArrow={false}
                    render={article => (
                      <StyledMobileBlock key={article.id} className="py-2 px-2 px-sm-0">
                        <StyledArticleCard>
                          <StyledArticleRowWrapper>
                            <ArticleRow
                              article={article}
                              noCategory
                              showContinueReadingButton
                              showAbstract
                              datetimeFormat="YYYY-MM-DD"
                            />
                          </StyledArticleRowWrapper>
                        </StyledArticleCard>
                      </StyledMobileBlock>
                    )}
                    showDots
                    dotsClassName="mt-3"
                    loop
                  />
                ) : (
                  <StyledMobileBlock className="py-2">
                    <StyledArticleCard>
                      <StyledArticleRowWrapper>
                        <ArticleRow
                          article={articles[0]}
                          noCategory
                          showContinueReadingButton
                          showAbstract
                          datetimeFormat="YYYY-MM-DD"
                        />
                      </StyledArticleRowWrapper>
                    </StyledArticleCard>
                  </StyledMobileBlock>
                )}
              </>
            )}
          </Skeleton>
        </div>
      </Responsive.Media>

      <div className="d-flex justify-content-center">
        <Link href="/articles" passHref legacyBehavior>
          <Button shape="round" className="mt-2 px-5">
            <FormattedMessage id="page.button.showMore" />
          </Button>
        </Link>
      </div>
    </HomePageSection>
  )
}

export default HomeArticleSection
