'use client'

import { APP_GET_ACTIVITIES } from '@havppen/gql/src/activity'
import { APP_GET_APPOINTMENTS } from '@havppen/gql/src/appointment'
import types from '@havppen/gql/types'
import React from 'react'
import CarouselSection from 'src/components/carousel'
import { SEOHead } from 'src/components/global/SEOWrapper'
import { GetPropsFunc } from 'src/components/hoc'
import DefaultLayout from 'src/components/layouts/DefaultLayout'
import { useApp } from 'src/contexts/AppContext'
import { GET_ARTICLES } from 'src/gql/article'
import { GET_CAROUSEL } from 'src/gql/carousel'
import { GET_COURSES } from 'src/gql/course'
import { GET_MERCHANDISES } from 'src/gql/merchandise'
import { mapActivitiesQueryOptionsToGqlVariables } from 'src/hooks/activity'
import { mapAppointmentsQueryOptionsToGqlVariables } from 'src/hooks/appointment'
import { mapArticlesQueryOptionsToGqlVariables } from 'src/hooks/article'
import { mapCoursesQueryOptionsToGqlVariables } from 'src/hooks/course'
import { mapMerchandisesQueryOptionsToGqlVariables } from 'src/hooks/merchandise'
import { PageProps } from 'src/types/shared'
import HomeActivitySection from './HomeActivitySection'
import HomeAppointmentSection from './HomeAppointmentSection'
import HomeArticleSection from './HomeArticleSection'
import HomeCourseSection from './HomeCourseSection'
import HomeMerchandiseSection from './HomeMerchandiseSection'
import HomePromotionSection from './HomePromotionSection'

const DefaultHomepage: React.FC<PageProps> = () => {
  const { enabledModules } = useApp()
  return (
    <DefaultLayout>
      <SEOHead />

      <CarouselSection block="home_main" priority className="mb-4 mb-sm-0" />
      <HomePromotionSection />
      {enabledModules.activity && <HomeActivitySection />}
      {enabledModules.course && <HomeCourseSection />}
      {enabledModules.appointment && <HomeAppointmentSection />}
      {enabledModules.merchandise && <HomeMerchandiseSection />}
      {enabledModules.article && <HomeArticleSection />}
    </DefaultLayout>
  )
}

export const getStaticProps: GetPropsFunc = async (context, { apolloClient, appId }) => {
  let revalidate: number | undefined = undefined
  const isPreview = context.preview ?? null

  if (appId) {
    await Promise.all([
      apolloClient.query<types.GET_CAROUSEL, types.GET_CAROUSELVariables>({
        query: GET_CAROUSEL,
        variables: {
          condition: {
            block: { _eq: 'home_main' },
            status: { _eq: 'published' },
            app_id: { _eq: appId },
          },
        },
      }),
      apolloClient.query<types.GET_CAROUSEL, types.GET_CAROUSELVariables>({
        query: GET_CAROUSEL,
        variables: {
          condition: {
            block: { _eq: 'home_discount' },
            status: { _eq: 'published' },
            app_id: { _eq: appId },
          },
        },
      }),
      apolloClient.query<types.GET_COURSES, types.GET_COURSESVariables>({
        query: GET_COURSES,
        variables: mapCoursesQueryOptionsToGqlVariables({ limit: 4, status: 'published' }, { appId }),
      }),
      apolloClient.query<types.APP_GET_ACTIVITIES, types.APP_GET_ACTIVITIESVariables>({
        query: APP_GET_ACTIVITIES,
        variables: mapActivitiesQueryOptionsToGqlVariables({ limit: 4, showExpired: true }, { appId }),
      }),
      apolloClient.query<types.APP_GET_APPOINTMENTS, types.APP_GET_APPOINTMENTSVariables>({
        query: APP_GET_APPOINTMENTS,
        variables: mapAppointmentsQueryOptionsToGqlVariables({ limit: 4 }, { appId }),
      }),
      apolloClient.query<types.GET_MERCHANDISES, types.GET_MERCHANDISESVariables>({
        query: GET_MERCHANDISES,
        variables: mapMerchandisesQueryOptionsToGqlVariables({ limit: 4 }, { appId }),
      }),
      apolloClient.query<types.GET_ARTICLES, types.GET_ARTICLESVariables>({
        query: GET_ARTICLES,
        variables: mapArticlesQueryOptionsToGqlVariables({ limit: 4, orderBy: 'latest' }, { appId }),
      }),
    ])
    revalidate = 60
  }

  return {
    revalidate,
    props: { isPreview },
  }
}

export default DefaultHomepage
