'use client'

import { CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Button, Segmented } from 'antd'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import ActivityCard from 'src/components/activity/ActivityCard'
import { useActivities } from 'src/hooks/activity.client'
import HomePageSection from '../default/HomePageSection'
import HomeCardListBlock, { HomeCardListResponsiveOptions } from './HomeCardListBlock'
const ProductEventCalendar = dynamic(() => import('src/components/common/ProductEventCalendar'), { ssr: false })

const HomeActivitySection: React.FC<{ responsiveOptions?: HomeCardListResponsiveOptions }> = ({
  responsiveOptions,
}) => {
  const { loadingActivities, activities } = useActivities({
    limit: 4,
    showExpired: true,
  })
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView && activities.length > 0) {
      window.gtag?.('event', 'view_item_list', {
        items: activities.map(activity => {
          const categoryName = activity.categories[0]?.name || ''
          const memberName = activity.instructors[0]?.displayName || ''
          const listPrice = activity.listPrice
          const price = activity.listPrice
          const discount = listPrice - price

          return {
            item_id: `Activity_${activity.id}`,
            item_name: activity.title,
            item_category: 'Activity',
            item_category2: categoryName,
            item_category3: memberName,
            quantity: '1',
            discount,
            price,
          }
        }),
      })
    }
  }, [inView, activities])

  const [calendarDisplayType, setCalendarDisplayType] = useState('card')
  if (!loadingActivities && activities.length === 0) {
    return null
  }

  return (
    <div ref={ref}>
      <HomePageSection
        loading={loadingActivities}
        center
        title="所有活動"
        extra={
          <Segmented
            value={calendarDisplayType}
            onChange={value => setCalendarDisplayType(value.toString())}
            options={[
              { label: '卡片', icon: <UnorderedListOutlined />, value: 'card' },
              { label: '日曆', icon: <CalendarOutlined />, value: 'calendar' },
            ]}
          />
        }
      >
        {calendarDisplayType === 'card' ? (
          <HomeCardListBlock
            className="mt-3 mt-sm-4"
            responsiveOptions={responsiveOptions}
            loading={loadingActivities}
            dataSource={activities}
            render={(activity, index) => <ActivityCard activity={activity} priority={index === 0} />}
          />
        ) : (
          <div className="container">
            <ProductEventCalendar
              productEvents={activities.flatMap(activity =>
                activity.sessions.map(session => ({
                  id: activity.id,
                  title: activity.title,
                  featuredImageUrl: activity.featuredImageUrl,
                  path: activity.path,
                  startAt: session.startAt,
                  endAt: session.endAt,
                })),
              )}
            />
          </div>
        )}

        <div className="d-flex justify-content-center">
          <Link href="/activities">
            <Button shape="round" className="mt-2 px-5">
              <FormattedMessage id="page.button.showMore" />
            </Button>
          </Link>
        </div>
      </HomePageSection>
    </div>
  )
}

export default HomeActivitySection
