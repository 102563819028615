'use client'

import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import { styled } from 'styled-components'

export const StyledTitleBlockDiv = styled.div`
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .description {
    font-size: 14px;
  }
  @media screen and (min-width: ${BREAK_POINT}px) {
    .title {
      font-size: 28px;
    }
    .description {
      font-size: 16px;
    }
  }
`
